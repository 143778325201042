import React from "react";
import Footer from "src/components/Footer";

class Page extends React.Component {
  render() {
    return (
      <div>
        
        <div className="addbg2">
          <div className="wrapper">
            <header>
              <h1 className="logo">
                <a href="/">Neutrogena</a>
              </h1>
              <nav>
                <a href="/produkty/" className="link">
                  Produkty
                </a>
                <span>| </span>
                <a href="/neutrogena/o-nas/" className="link">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>| </span>
                <a href="/budte-krasna/" className="link">
                  Buďte krásná
                </a>
                <span>| </span>
                <a href="/nordic-berry/" className="link">
                  Nordic Berry
                </a>
                <span />
                <span>|</span>
                <a href="/plet-s-nedokonalostmi/">Pleť s nedokonalostmi</a>
                <span />
              </nav>
            </header>
            <div className="content">
              <div className="breadcrumb">
                <a href="/">
                  NEUTROGENA<sup>®</sup>
                </a>
                <span>&gt;</span>
                <a href="/produkty/">Popis</a>
                <span>&gt;</span>
                <a href="/product/72/#" className="blue">
                  {" "}
                  <p>
                    NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Pink
                    Grapefruit Krémová čisticí emulze
                  </p>{" "}
                </a>
              </div>
              <div className="singleproduct">
                <div className="prodimgcont">
                  <div className="bot">
                    <img
                      src="/assets/Uploads/NEUTROGENA-Visibly-Clear-kremova-emulze-grepfruit-150ml.jpg"
                      alt="NEUTROGENA Visibly Clear kremova emulze grepfruit 150ml"
                    />
                  </div>
                </div>
                <div className="info">
                  <h2>
                    {" "}
                    <p>
                      NEUTROGENA<sup>®</sup> VISIBLY CLEAR<sup>®</sup> Pink
                      Grapefruit Krémová čisticí emulze
                    </p>
                  </h2>
                  <dl>
                    <dt>Popis:</dt>
                    <dd>
                      <p>
                        Osvěžující emulze pro pleť čistší, svěžejší a zdravě
                        vypadající každý den. Jemná krémová emulze obohacená o
                        technologii MICROCLEAR<sup>®</sup> čistí pleť do
                        hloubky, odstraňuje nečistoty a mastnotu a pomáhá tak
                        redukovat pupínky a černé tečky. Emulze s krémovým
                        složením čistí pleť, aniž by ji přesušovala. Vůně
                        růžového grapefruitu povznese vaše smysly a dodá Vám
                        svěžest. Dermatologicky testováno, vhodné i pro citlivou
                        pleť.
                      </p>
                    </dd>
                  </dl>
                  <dl>
                    <dt>Použití:</dt>
                    <dd>
                      Každé ráno a večer aplikujte na navlhčené ruce a
                      vmasírujte na obličej. Důkladně opláchněte. Zabraňte
                      vniknutí do očí. Dojde-li k němu, řádně vypláchněte oči
                      vodou. Pouze pro vnější použití.
                    </dd>
                  </dl>
                  <dl className="last">
                    <dt>Balení:</dt>
                    <dd>150 ml</dd>
                  </dl>
                </div>
                <div id="fb_30"></div>
              </div>
              <div className="products">
                <div className="row">
                  <div className="rowtitle">Zobrazit související produkty:</div>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/99/"
                  >
                    <img
                      src="/assets/Uploads/neutrogena-serum.png"
                      alt="neutrogena serum"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Intenzivní Hydratační Sérum
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/100/"
                  >
                    <img
                      src="/assets/Uploads/NTG-HydroBoost-TMW-400mlv.jpg"
                      alt="NTG HydroBoost TMW 400mlv"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>® </sup>Hydro&nbsp;Boost<sup>®</sup>{" "}
                        Micelární voda 3v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/102/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-WashMask-150ml-bez-stinu.jpg"
                      alt="NTG SkinDetox WashMask 150ml bez stinu"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup>
                        <sup>&nbsp; </sup>Skin Detox 2v1 Čistící emulze a maska
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/103/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-TMW-400ml-002f.jpg"
                      alt="NTG SkinDetox TMW 400ml 002f"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Micelární voda 3v1
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                    <span className="border" />
                  </a>
                  <a
                    className="product group1_1 group2_1 group3_1"
                    href="/product/106/"
                  >
                    <img
                      src="/assets/Uploads/NTG-SkinDetox-Peeling-150ml-bez-stinu2.jpg"
                      alt="NTG SkinDetox Peeling 150ml bez stinu2"
                    />
                    <span className="txt">
                      <p>
                        NEUTROGENA<sup>®</sup> Skin Detox Pleťový peeling
                      </p>
                    </span>
                    <span className="button">Podrobnosti</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="bluebar">
          <div className="bar">
            <div className="wrapper">
              <a href="/neutrogena/o-nas/">
                O ZNAČCE NEUTROGENA<sup>®</sup>
              </a>
              <span>|</span>
              <a href="/neutrogena/norska-receptura/">Norská receptura</a>
              <span>|</span>
              <a href="/neutrogena/historie/">Seznamte se s naší historií</a>
              <span>|</span>
              <a href="/produkty/">
                Zobrazit produkty NEUTROGENA<sup>®</sup>
              </a>
            </div>
          </div>
        </div>
        <Footer></Footer>
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        <script
          dangerouslySetInnerHTML={{
            __html:
              "\n$(document).ready(function () {\n$('a').each(function () {\nvar linkhtml = $(this).html();\nif (linkhtml.indexOf('®') > -1 && linkhtml.indexOf('<sup>®</sup>') == -1) {\n$(this).html(linkhtml.replace(\"®\", \"<sup>®</sup>\"));\n}\n});\n});\n"
          }}
        />
        
      </div>
    );
  }
}

export default Page;
